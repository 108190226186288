import React from 'react';
import classes from './ImageButton.module.css';

interface ImageButtonProps {
  imageName: string;
  linkUrl: string;
}

const SocialMediaButton: React.FC<ImageButtonProps> = ({ imageName, linkUrl }) => {
  const imageDir: string = "/socialmedias/"
  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
      <img src={imageDir+imageName} alt="img" className={classes.image}/>
    </a>
  );
};

const ToWorks: React.FC = () => {
  return (
    <a href="/works">
      <img src="/to_works_logo.webp" alt="img" className={classes.to_works}/>
    </a>
  );
};
export {SocialMediaButton, ToWorks};
