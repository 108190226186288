
import { Route, Routes } from "react-router-dom";
import Home  from "./pages/Home";
import Works from "./pages/Works";


export const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/works" element={<Works/>}/>
        </Routes>
    );
}

export default Router;