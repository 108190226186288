// Home.tsx
import React from 'react';
import {MediaCardContainer} from '../organisms/MediaCardContainer'; 
import Members from '../molecules/Members';
import { RightTopTriangle, LeftTopTriangle, RightBottomTriangle, LeftBottomTriangle } from '../atoms/Triangles';
import { ToWorks } from '../atoms/ImageButton';
import './Home.module.css';
import classes from './Home.module.css';


const Home: React.FC = () => {
  return (
    <div className={classes.global}>
      <div>
        <div className={classes.line}>
          <LeftTopTriangle />
          <RightTopTriangle />
          <h1>SYZYGY.</h1>
          <LeftBottomTriangle />
          <RightBottomTriangle />
        </div>
        <div className={classes.line}>
          <LeftTopTriangle />
          <RightTopTriangle />
          <h2>SYZYGY IS A<br></br>
            CREATIVE TEAM OF<br></br>
            ARTISTS BASED IN TOKYO,<br></br>
            SHIMKITAZAWA
          </h2>
          <h3>wsygyzy@gmail.com</h3>
          <LeftBottomTriangle />
          <RightBottomTriangle />
        </div>
        <div className={classes.line}>
          <LeftTopTriangle />
          <RightTopTriangle />
          <div className={classes.image_container}>
            <img src='/circle.webp' alt='circle' className={classes.circle}/>
            <img src='/brain.webp' alt='brain'  className={classes.brain}/>
            <img src='/pen.webp' alt='brain' className={classes.pen}/>
          </div>
          <LeftBottomTriangle />
          <RightBottomTriangle />
        </div>
      </div>  
      <div className={classes.line_bottom}>
        <LeftTopTriangle />
        <RightTopTriangle />
        <div>
          <h1>WORKS</h1>
          <MediaCardContainer />
        </div>
        <div>
          <Members />
          <br></br>
          <ToWorks />
          <br></br>
        </div>
        <LeftBottomTriangle />
        <RightBottomTriangle />
      </div>
    </div>
  );
};

export default Home;
