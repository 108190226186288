import React from 'react';
import classes from './MediaCard.module.css'
import { IndexTriangle } from '../atoms/Triangles';
import mediumZoom from 'medium-zoom';

interface MediaCardFromYtProps {
  videoID: string;
  description: string;
}

interface MediaCardFromImgProps {
  linkURL: string;
  imageName: string;
  description: string;
}

const MediaCardFromYt: React.FC<MediaCardFromYtProps> = ({ videoID, description }) => {
  var thumbnailURL: string
  var videoURL: string
  thumbnailURL = "http://img.youtube.com/vi/" + videoID + "/maxresdefault.jpg"
  videoURL = "https://www.youtube.com/watch?v=" + videoID
  return (
    <div className={classes.genaral}>
      <a href={videoURL} target="_blank" rel="noopener noreferrer">
        <p className={classes.resize_image}>
          <img src={thumbnailURL} alt="yt thumbnail" />
        </p>
        <div>
          <div className={classes.index}>
            <IndexTriangle />
          </div>
          <h3 className={classes.title}>
            {description.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== description.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </h3>
        </div>
      </a>
    </div>
  );
};

const MediaCardFromImg: React.FC<MediaCardFromImgProps> = ({ linkURL, imageName, description }) => {
  const imageDir: string = "/images/"
  return (
    <div className={classes.general} >
      <a href={linkURL} target="_blank" rel="noopener noreferrer">
        <p className={classes.resize_image} >
          <img src={imageDir + imageName} alt="img thumbnail" />
        </p>
        <div>
          <div className={classes.index}>
            <IndexTriangle />
          </div>
          <h3 className={classes.title}>
            {description.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== description.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </h3>
        </div>
      </a>
    </div>
  )
}

const MediaCardForWorks: React.FC<MediaCardFromImgProps> = ({ linkURL, imageName, description }) => {
  const imageDir: string = "/works/"
  mediumZoom('.resize_works_image img', { background: '#000', margin: 0 });
  return (
    <div className={classes.general} >
      <p className={classes.resize_works_image} >
        <img src={imageDir + imageName} alt="img thumbnail" />
      </p>
      <div>
        <a href={linkURL} target="_blank" rel="noopener noreferrer">
          <IndexTriangle />
          <h3 className={classes.title}>
            {description.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== description.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </h3>
        </a>
      </div>
    </div>
  )
};

export { MediaCardFromYt, MediaCardFromImg, MediaCardForWorks };
