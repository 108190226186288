import React from 'react';
import { MediaCardFromYt, MediaCardFromImg, MediaCardForWorks } from '../molecules/MediaCard';
import classes from './MediaCardContainer.module.css';

// cards.jsonのデータを読み込む（この例では、cards.jsonがpublicフォルダにあると仮定）
import cardsData from '../datas/card_info.json';
import worksCardsData from '../datas/works_card_info.json';

interface Card {
  video_id: string;
  title: string;
  link_url: string;
  image_name: string;
  is_youtube: boolean;
}

interface WorksCard {
  title: string;
  image_name: string;
  link_url: string;
}

const MediaCardContainer = () => {
  return (
    <div className={classes.container}>
      {cardsData.map((card: Card, index) => (
        <div key={index}>
          {card.is_youtube ? (
            // is_youtubeがtrueの場合MediaCardFromYtのコンポーネントを配置する
            <MediaCardFromYt
              key={index}
              videoID={card.video_id}
              description={card.title}
            />
          ) : (
            // is_youtubeがfalseの場合MediaCardFromImgのコンポーネントを配置する
            <MediaCardFromImg
              key={index}
              linkURL={card.link_url}
              imageName={card.image_name}
              description={card.title}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const WorksMediaCardContainer = () => {
  // フィルタリングして2つの配列に分ける
  const squareCards = worksCardsData.filter(card => card.is_square);
  const nonSquareCards = worksCardsData.filter(card => !card.is_square);

  // 配列を is_square でソートする
  const sortedSquareCards = squareCards.sort((a, b) => a.title.localeCompare(b.title));
  const sortedNonSquareCards = nonSquareCards.sort((a, b) => a.title.localeCompare(b.title));
  

  return (
    <div className={classes.works_container}>
      {sortedNonSquareCards.map((card: WorksCard, index) => (
        <li key={index} className={classes.works}>
          <MediaCardForWorks
            key={index}
            imageName={card.image_name}
            description={card.title}
            linkURL={card.link_url}
          />
        </li>
      ))}
      {sortedSquareCards.map((card: WorksCard, index) => (
        <li key={index} className={classes.works_square}>
          <MediaCardForWorks
            key={index}
            imageName={card.image_name}
            description={card.title}
            linkURL={card.link_url}
          />
        </li>
      ))}
    </div>
  );
}

export {MediaCardContainer, WorksMediaCardContainer};
