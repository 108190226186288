import React from 'react';
import { RightTopTriangle, LeftTopTriangle, RightBottomTriangle, LeftBottomTriangle } from '../atoms/Triangles';
import { WorksMediaCardContainer } from '../organisms/MediaCardContainer';
import classes from './Works.module.css';

const Works: React.FC = () => {
    return (
        <div className={classes.global}>
            <div className={classes.line}>
                <img src="/anthill_records.webp" alt="works" className={classes.andhill} />
                <LeftTopTriangle />
                <RightTopTriangle />
                <WorksMediaCardContainer />
                <LeftBottomTriangle  />
                <RightBottomTriangle />
            </div>
        </div>

    )
};  

export default Works;