import classes from './Triangles.module.css';

const LeftTopTriangle: React.FC = () => {
    return (
    <div className={classes.left_top_triangle}></div>
    )
};

const RightTopTriangle: React.FC = () => {
    return (
    <div className={classes.right_top_triangle}></div>
    )
}

const LeftBottomTriangle: React.FC = () => {
    return (
    <div className={classes.left_bottom_triangle}></div>
    )
}

const RightBottomTriangle: React.FC = () => {
    return (
    <div className={classes.right_bottom_triangle}></div>
    )
}

const IndexTriangle: React.FC = () => {
    return (
    <div className={classes.index_triangle}></div>
    )
}


export {LeftTopTriangle, RightTopTriangle, LeftBottomTriangle, RightBottomTriangle, IndexTriangle};