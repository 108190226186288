import {SocialMediaButton} from "../atoms/ImageButton"
import classes from './Members.module.css';

const Members: React.FC = () => {
    return (
    <table className={classes.members}>
      <tbody>
        <tr>
          <td className={classes.left_border}>USAGIDA KOTARO</td>
          <td>MV Director / Producer</td>
          <td>          
            <SocialMediaButton imageName="x.webp" linkUrl="https://twitter.com/kame_yori_hayai" />
            &nbsp;
            <SocialMediaButton imageName="instagram.webp" linkUrl="https://www.instagram.com/kame_yori_hayai" />
          </td>
        </tr>
        <tr>
          <td className={classes.left_border} >W1W</td>
          <td>Creative Director / Stylist</td>
          <td>          
          <SocialMediaButton imageName="instagram.webp" linkUrl="https://www.instagram.com/w1w_1389" />
          </td>
        </tr>
        <tr>
          <td className={classes.left_border}>K2K</td>
          <td>Designer</td>
          <td>
            <SocialMediaButton imageName="instagram.webp" linkUrl="https://www.instagram.com/k2k_visualimages/" />
          </td>
        </tr>
      </tbody>
    </table>
    )
};

export default Members;